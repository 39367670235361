<template>
  <v-theme-provider light>
    <v-form ref="form">
      <v-overlay :value="isPageLoading">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <div v-if="isAcceptedProp && !isPageLoading" :timeout="infinityTimeoutProp">
        <BannerAccepted />
      </div>
      <div v-if="isRejectedProp && !isPageLoading" :timeout="infinityTimeoutProp">
        <BannerRejected />
      </div>
      <div v-if="!wasEulaAlreadyAcceptedProp && !showRedeemFormProp && !isPageLoading">
        <BannerVirgin />
      </div>
      <div v-if="isSubmittedProp && !isPageLoading">
        <BannerSubmitted />
      </div>
      <div justify="center" align="center" v-if="showRedeemFormProp && !isPageLoading">
        <ComponentRedeem @callRefreshProfileAndProperties="refreshProfileAndProperties" v-model="showRedeemFormProp" />
      </div>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12">
          <v-snackbar v-model="showGenericFailure" :timeout="infinityTimeoutProp">
            <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
              <span class="centered">
                <span>That's unexpected! {{ genericFailureErrorMessage }}</span>
                <v-spacer />
                <v-btn @click="showGenericFailure = false" text style="display: flex; justify-content: flex-end">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </span>
            </v-container>
          </v-snackbar>
        </v-col>
      </v-row>
      <base-section id="profile" class="text-center" space="86">
        <div v-if="!showRedeemFormProp && !isPageLoading">
          <base-section-heading v-if="isNotSubmittedProp" title="Get Started" color="accent"><v-icon left>mdi-web</v-icon>{{ networkname }}</base-section-heading>
          <base-section-heading v-else :title="fullname" color="accent"><v-icon left>mdi-web</v-icon>{{ networkname }}</base-section-heading>
          <v-responsive class="d-flex align-center mx-auto mb-4" height="100%" max-width="800" width="100%">
            <v-container>
              <div class="panel" v-on:input="formDirty()">
                <v-row>
                  <v-col class="col">
                    <template>
                      <v-card elevation="1" :loading="isPageLoading">
                        <v-container fluid color="primary">
                          <br />
                          <v-row align="center" justify="center">
                            <v-col cols="12" sm="12">
                              <v-avatar tile :size="avatarSize">
                                <v-icon :size="avatarSize" color="grey lighten-1" v-show="!formProfile.avatar">mdi-account-circle</v-icon>
                                <v-img class="profile-image-style" :src="formProfile.avatar" v-show="formProfile.avatar"></v-img>
                                <div v-if="isUploadingAvatar" :class="[formProfile.avatar ? 'progress-square-overlay' : 'progress-round-overlay']">
                                  <v-progress-circular indeterminate :size="avatarSize / 2" :color="formProfile.avatar ? 'white' : 'accent'"></v-progress-circular>
                                </div>
                              </v-avatar>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <v-card elevation="0" class="d-flex justify-center">
                                <template v-if="isUploadingAvatar">
                                  <v-btn fab text class="nohover-button" disabled>
                                    <v-icon color="accent">mdi-upload</v-icon>
                                  </v-btn>
                                </template>
                                <template v-else>
                                  <v-btn fab text v-ripple="false" class="nohover-button" @click="onImagePicker">
                                    <v-icon color="accent">mdi-upload</v-icon>
                                  </v-btn>
                                </template>
                                <v-btn fab text :disabled="!formProfile.avatar" class="nohover-button" @click="clearFile">
                                  <v-icon color="accent">mdi-trash-can-outline</v-icon>
                                </v-btn>
                              </v-card>
                              <!-- Create a File Input that will be hidden but triggered with JavaScript -->
                              <input ref="fileupload" class="d-none" accept="image/png, image/jpeg" type="file" @change="onImageUpload" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                      <v-spacer /><br />
                      <v-card elevation="1">
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.firstname" :rules="rules.field" label="First name" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.lastname" :rules="rules.field" label="Last name" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field v-if="isEditingForbiddenProp" v-model="formProfile.profession" name="profession" label="Profession" required readonly />
                              <v-select v-else v-model="formProfile.professioncode" name="profession" :items="professions" item-text="name" item-value="id" :rules="rules.professioncode" label="Profession" required @change="formDirty" />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.sales" :rules="rules.sales" color="primary" label="Customers served" hint="Number of sales in the past 12 months" required />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                      <v-spacer><br /></v-spacer>
                      <v-card elevation="2">
                        <v-card-title>Location</v-card-title>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.city" :rules="rules.field" label="City" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field v-if="isEditingForbiddenProp" v-model="formProfile.zip" label="Zip Code" required readonly />
                              <v-text-field v-else outlined v-model="formProfile.zip" :rules="rules.zip" label="Zip Code" required />
                            </v-col>
                          </v-row>
                          <ComponentStatesCounties :stateCodeProp="this.formProfile.state" :countyCodeProp="this.formProfile.countycode" ref="componentStatesCounties" @callUpdateStateCountyProps="updateStateCountyProps" />
                        </v-container>
                      </v-card>
                      <v-spacer><br /></v-spacer>
                      <v-card elevation="2">
                        <v-card-title>Contact</v-card-title>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.business" :rules="rules.field" label="Company Name" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.email" :rules="rules.field" label="Email Address" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.phone" :rules="rules.field" label="Telephone Number" required />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field outlined v-model="formProfile.license" label="License Number" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                      <v-spacer><br /></v-spacer>
                      <v-card elevation="2">
                        <div class="d-flex align-center justify-space-between">
                          <v-card-title> Summary </v-card-title> <br />
                          <v-card-subtitle>optional</v-card-subtitle>
                        </div>
                        <v-container fluid>
                          <v-col cols="12">
                            <v-textarea v-model="formProfile.summary" :placeholder="'Professional in ' + this.formProfile.county + ', based in the ' + this.formProfile.zip + ' zip code with ' + this.formProfile.sales + ' or more sales in the past 12 months.'">
                              <template v-slot:label> Write a few lines about yourself: </template>
                            </v-textarea>
                          </v-col>
                        </v-container>
                      </v-card>
                      <v-spacer><br /></v-spacer>
                      <v-card elevation="2">
                        <div class="d-flex align-center justify-space-between">
                          <v-card-title>External Profiles</v-card-title><br />
                          <v-card-subtitle>optional</v-card-subtitle>
                        </div>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="12" v-for="(website, i) in formProfile.websites" :key="i" class="text-fields-row">
                              <v-text-field outlined label="Website address" v-model="formProfile.websites[i]" placeholder="https://…" />
                              <v-btn icon text @click="removeWebsite(i)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                              <v-btn icon text :href="formProfile.websites[i]" target="_blank"> <v-icon>mdi-chevron-right-circle</v-icon> </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-btn color="white" @click="addWebsite" class="nohover-button" v-ripple="false">
                                <v-icon left color="accent"> mdi-plus </v-icon>
                                Add a website
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                      <v-spacer><br /></v-spacer>
                      <v-card elevation="2">
                        <div class="d-flex align-center justify-space-between">
                          <v-card-title> Video Presentation</v-card-title><br />
                          <v-card-subtitle>optional</v-card-subtitle>
                        </div>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-row class="video-max-width" v-if="formProfile?.video?.length > 0">
                                <iframe :src="embededYoutubeURL(formProfile.video)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                              </v-row>
                              <br />
                              <v-text-field v-model="formProfile.video" placeholder="https://www.youtube.com…" label="Link to your YouTube video" clearable @click:clear="clearVideoUrl()" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-snackbar v-model="showSavedFailure" :timeout="longTimeoutProp" color="primary">
                      <v-row justify="center" align="center">
                        <v-container fluid mt-2 mb-2 fill-width style="width: 100%"
                          ><div color="white">{{ savedFailureErrorMessage }}.</div>
                        </v-container>
                      </v-row>
                      <v-container fluid mt-2 fill-width style="width: 100%">
                        <v-row justify="center" align="center"> <br /><v-btn align="center" light @click="showSavedFailure = false">Got it</v-btn><br /> </v-row
                      ></v-container>
                    </v-snackbar>
                  </v-col>
                </v-row>
              </div>
              <br /><br /><br /><br /><br />
              <v-footer v-if="!isPageLoading" :fixed="true" :color="footerColorProp">
                <v-row justify="center" align="center" class="footer">
                  <v-col cols="12" sm="12">
                    <v-snackbar loading v-model="showSavedProgress" :timeout="longTimeoutProp">
                      Saving…
                      <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    </v-snackbar>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center" class="footer">
                  <v-col>
                    <!-- <v-snackbar loading v-model="showSavedSuccess" :timeout="shortTimeoutProp" color="success"> Profile saved </v-snackbar> -->
                    <v-snackbar v-model="showSavedSuccess" :timeout="shortTimeoutProp" color="success">
                      <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
                        <span class="centered">
                          <span> Profile saved</span>
                          <v-spacer />
                          <v-btn @click="showSavedSuccess = false" text style="display: flex; justify-content: flex-center">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </span>
                      </v-container>
                    </v-snackbar>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center" class="footer">
                  <v-col cols="12" sm="12">
                    <v-container v-if="isNotSubmittedProp || isRejectedProp || isSubmittedProp" fluid>
                      <div class="centered" style="height: 0px; display: flex; justify-content: flex-start">
                        <v-checkbox form-input :disabled="wasEulaAlreadyAcceptedProp" :rules="[(v) => !!v || 'You must agree to continue']" v-model="eulaaccepted" color="accent" :true-value="eulaaccepted"> <template v-slot:label> I have read and accepted the&nbsp; </template> </v-checkbox
                        ><span bottom style="position: relative; bottom: -50"><a href="javascript:;" @click.stop="eulaDialogIsVisible = true">EULA</a></span>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
                <v-dialog v-model="eulaDialogIsVisible" transition="dialog-bottom-transition">
                  <v-card>
                    <v-card-title class="title">EULA</v-card-title>
                    <v-card-subtitle class="title"> {{ eula1 }} </v-card-subtitle>
                    <v-card-text>
                      {{ eula2 }}<br /><br />
                      {{ eula3 }}<br /><br />
                      {{ eula4 }}
                    </v-card-text>
                    <v-card-subtitle class="title"> {{ eula }} </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn block color="warning" @click="eulaAction()"> I hereby agree </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-row justify="center" align="center" class="footer">
                  <v-col cols="12" sm="12">
                    <!-- First Time Edit Application -->
                    <v-card-actions v-if="isNotSubmittedProp || isRejectedProp">
                      <v-spacer />
                      <v-btn text color="primary" @click="updateProfileAction()"> Save progress </v-btn>
                      <v-spacer />
                      <v-btn color="primary" @click="submitApplicationAction()">Submit</v-btn>
                    </v-card-actions>

                    <!-- Revise Application -->
                    <v-card-actions v-if="isSubmittedProp">
                      <v-spacer />
                      <v-btn color="primary" @click="updateProfileAction()"> Update application </v-btn>
                    </v-card-actions>
                  </v-col>
                  <!-- Edit Profile -->
                  <v-col cols="12" sm="12">
                    <v-card-actions class="absolute-buttons pl-8 pr-8 mb-6" v-if="isAcceptedProp || isCompletedProp">
                      <v-btn class="d-flex justify-end" color="error" fab @click="$router.push({ name: 'profileroute' })"><v-icon left>mdi-close</v-icon></v-btn>
                      <v-spacer />
                      <v-btn class="d-flex justify-end" color="success" fab @click="updateProfileAction()"><v-icon left>mdi-check</v-icon></v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-footer>
            </v-container>
          </v-responsive>
        </div>
      </base-section>
    </v-form>
  </v-theme-provider>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { AutoComplete } from '@/AutoComplete.ts'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX } from '@/environments/EnvX.ts'
import { ProfessionsData } from '@/resources/data/ProfessionsData.ts'
import { PubSub } from '@/publishsubscribe/pub-sub'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import { StatesNamesData } from '@/resources/data/StatesNamesData.ts'
import { WebTools } from '@/WebTools.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  components: {
    BannerAccepted: () => import('./BannerAccepted.vue'),
    BannerRejected: () => import('./BannerRejected.vue'),
    BannerSubmitted: () => import('./BannerSubmitted'),
    BannerVirgin: () => import('./BannerVirgin.vue'),
    ComponentRedeem: () => import('./ComponentRedeem.vue'),
    ComponentStatesCounties: () => import('./ComponentStatesCounties.vue')
  },
  data() {
    const defaultForm = Object.freeze({
      formProfile: {}
    })

    return {
      avatarSuccessfullyUploadedAlert: false,
      states: StatesNamesData,
      professions: ProfessionsData,
      form: Object.assign({}, defaultForm),
      rules: {
        eula: [(v) => v.checked === true || 'You must agree to continue!'],
        field: [(v) => (v || '').length > 0 || 'This field is required'],
        professioncode: [(v) => !!v || 'Profession is required'],
        sales: [(v) => String(v).length > 0 || 'This field is required', (v) => (Number.isInteger(Number(v)) && v > 0) || 'At least one sale in the past 12 months'],
        zip: [(v) => !!v || 'Zip is required', (v) => (!isNaN(parseFloat(v)) && v && v.length <= 5) || 'ZIP must be 5 digits']
      },
      eula: 'I hereby agree to this End User License Agreement (EULA).',
      eula1: 'By using the ProInsight platform I acknowledge:',
      eula2: '1️⃣ Stripe© is our third party payment service. ProInsight never stores your payment information. Your Stripe password is solely managed by Stripe©.',
      eula3: '2️⃣ Microsoft Active Directory is our third party customer identity access management service (CIAM). Your ProInsight access password is solely managed by this CIAM.',
      eula4: '3️⃣ ⚠️ Absolutely EVERYTHING you enter in your ProInsight Application and Profile is PUBLIC information.  It can be copied, cloned, downloaded by anyone who visits the site. Choose your profile picture and your password carefully. Do not give more information that you do not want anyone, from benevolent to malicious actors, to have access to.',
      terms: 'I hereby agree to some important other terms so I can go ahead and just submit!',
      defaultForm,
      isPageLoading: true,
      isUploadingAvatar: false,
      submitApplicationDialogIsVisible: false,
      formisdirty: false,
      formProfile: {},
      eulaDialogIsVisible: false,
      eulaaccepted: false,
      wasEulaAlreadyAccepted: false,
      formWebsitePreviewDialogIsVisible: false,
      registrationStage: RegistrationStage.Disabled, // https://stackoverflow.com/a/55379279
      fullname: '',
      networkname: '',
      professioncode: '',
      showSavedFailure: false,
      showSavedProgress: false,
      showSavedSuccess: false,
      savedFailureErrorMessage: '',
      showGenericFailure: false,
      genericFailureErrorMessage: '',
      avatarSize: 144
    }
  },

  computed: {
    // Application status
    //  -1  undefined
    //  0️⃣  application not submitted - diplay application - application banner on first time
    //  1️⃣  application submitted - switch to edit profile - edit banner
    //  2️⃣  (unused) application rejected - can edit profile - no edit banner
    //  3️⃣  application accepted - can edit profile - no edit banner - go to stripe banner
    //  4   (unused) completed

    footerColorProp() {
      return this.isAcceptedProp || this.isCompletedProp ? 'transparent' : 'white'
    },

    isNotSubmittedProp() {
      return this.registrationStage === RegistrationStage.NotSubmitted
    },

    isSubmittedProp() {
      return this.registrationStage === RegistrationStage.Submitted
    },

    isAcceptedProp() {
      return this.registrationStage === RegistrationStage.Accepted
    },

    isRejectedProp() {
      return this.registrationStage === RegistrationStage.Rejected
    },

    isCompletedProp() {
      return this.registrationStage === RegistrationStage.Completed
    },

    isEditingForbiddenProp() {
      switch (this.registrationStage) {
        case RegistrationStage.NotSubmitted:
        case RegistrationStage.Submitted:
          return false
      }
      return true
    },

    nullTimeoutProp() {
      return AlertTimeout.null
    },

    longTimeoutProp() {
      return AlertTimeout.long
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },

    shortTimeoutProp() {
      return AlertTimeout.short
    },

    showRedeemFormProp() {
      return this.registrationStage === RegistrationStage.Empty
    },

    wasEulaAlreadyAcceptedProp() {
      return this.wasEulaAlreadyAccepted
    }
  },

  mounted() {
    PubSub.subscribe(AuthServiceConstants.LOG_STATUS_CHANGED, this.fetchProfile)
    setTimeout(this.fetchProfile, this.nullTimeoutProp)
  },

  methods: {
    eulaAction() {
      this.eulaDialogIsVisible = false
      this.eulaaccepted = true
    },

    embededYoutubeURL(video) {
      return WebTools.embededYoutubeURL(video)
    },

    fetchProfile() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🙀 MyProfileEditSection.fetchProfile', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.genericFailureErrorMessage = ''
          this.isPageLoading = true
          this.showGenericFailure = false

          return true
        },

        // onSuccess
        (data) => {
          this.isPageLoading = false
          this.refreshProfileAndProperties(data.profile)
        },

        // All sort of errors
        (error, errorMessage) => {
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT !== error.message) {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorMessage
          }
        }
      )
    },

    updateProfileAction() {
      var stateCode = AutoComplete.NormalizeStateCode(this.formProfile.state)
      var cityCode = AutoComplete.NormalizedCode(stateCode, AutoComplete.CompactName(this.formProfile.city))

      const profilebody = {
        avatar: this.formProfile.avatar,
        business: this.formProfile.business,
        citycode: cityCode,
        countycode: this.formProfile.countycode,
        email: this.formProfile.email,
        eulaaccepted: Boolean(this.eulaaccepted),
        firstname: this.formProfile.firstname,
        lastname: this.formProfile.lastname,
        lat: Number(this.formProfile.lat),
        license: this.formProfile.license,
        lon: Number(this.formProfile.lon),
        phone: this.formProfile.phone,
        professioncode: this.formProfile.professioncode,
        sales: Number(this.formProfile.sales),
        statecode: stateCode,
        summary: this.formProfile.summary,
        video: this.formProfile.video,
        websites: this.formProfile.websites,
        zipcode: this.formProfile.zip
      }

      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '😳 MyProfileEditSection.updateProfile', // log hint
        endpoints.updateprofileEndpoint, // endpoint
        environmentVariables.UPDATE_PROFILE_KEY, // code
        profilebody, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.showSavedFailure = false
          this.showSavedProgress = true
          this.showSavedSuccess = false
          this.showGenericFailure = false
          this.savedFailureErrorMessage = ''

          return true
        },

        // onSuccess
        (data) => {
          this.formisdirty = false
          this.showSavedProgress = false
          this.showSavedSuccess = true
          this.professioncode = this.formProfile.professioncode
          this.refreshProfileAndProperties(data.profile)
          if (this.isAcceptedProp || this.isCompletedProp) {
            this.$router.push({
              name: 'profileroute'
            })
          }
        },

        // All sort of errors
        (error, errorMessage) => {
          this.showSavedProgress = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showSavedFailure = true
            this.savedFailureErrorMessage = errorMessage
          }
        }
      )
    },

    updateStateCountyProps(stateCode, countyCode) {
      this.formisdirty = true
      this.formProfile.state = stateCode
      this.formProfile.countycode = countyCode
    },

    submitApplicationAction() {
      this.$refs.form.validate()

      var stateCode = AutoComplete.NormalizeStateCode(this.formProfile.state)
      var cityCode = AutoComplete.NormalizedCode(stateCode, AutoComplete.CompactName(this.formProfile.city))

      const applicationsubmissionbody = {
        avatar: this.formProfile.avatar,
        business: this.formProfile.business,
        citycode: cityCode,
        countycode: this.formProfile.countycode,
        email: this.formProfile.email,
        eulaaccepted: Boolean(this.eulaaccepted),
        firstname: this.formProfile.firstname,
        lastname: this.formProfile.lastname,
        lat: Number(this.formProfile.lat),
        license: this.formProfile.license,
        lon: Number(this.formProfile.lon),
        phone: this.formProfile.phone,
        professioncode: this.formProfile.professioncode,
        sales: Number(this.formProfile.sales),
        statecode: stateCode,
        summary: this.formProfile.summary,
        video: this.formProfile.video,
        websites: this.formProfile.websites,
        zipcode: this.formProfile.zip
      }

      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🎁 MyProfileEditSection.submitApplication', // log hint
        endpoints.submitApplicationEndpoint, // endpoint
        environmentVariables.SUBMIT_APPLICATION_KEY, // code
        applicationsubmissionbody, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.savedFailureErrorMessage = ''
          this.showGenericFailure = false
          this.showSavedFailure = false
          this.showSavedProgress = true
          this.showSavedSuccess = false

          return true
        },

        // onSuccess
        (data) => {
          this.formisdirty = false
          this.showSavedProgress = false
          this.showSavedSuccess = true
          this.refreshProfileAndProperties(data.profile)
          this.$router.push({
            name: 'staytunedroute'
          })
        },

        // All sort of errors
        (error, errorMessage) => {
          this.showSavedProgress = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showSavedFailure = true
            this.savedFailureErrorMessage = errorMessage
          }
        }
      )
    },

    clearVideoUrl() {
      this.formProfile.video = ''
    },

    formDirty() {
      this.formisdirty = true
      this.$refs.form.validate()
    },

    refreshProfileAndProperties(profile) {
      this.formProfile = profile
      if (this.formProfile && !this.formProfile.websites) {
        this.formProfile.websites = []
      }
      this.registrationStage = profile.registrationstage
      this.fullname = profile.fullname
      this.networkname = profile.networkname
      this.professioncode = profile.professioncode

      this.eulaaccepted = Boolean(profile.eulaaccepted)
      this.wasEulaAlreadyAccepted = this.eulaaccepted
    },

    addWebsite() {
      this.formDirty()
      this.formProfile.websites.push('')
      EnvX.log(this.formProfile.websites)
    },

    removeWebsite(index) {
      this.formDirty()
      this.formProfile.websites.splice(index, 1)
    },

    onImagePicker() {
      // Trigger click on the FileInput
      this.$refs.fileupload.click()
    },

    onImageUpload(event) {
      const selectedFile = event.target.files[0]

      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '📸 MyProfileEditSection.onImageUpload', // log hint
        endpoints.myAvatarEndpoint, // endpoint
        environmentVariables.MY_AVATAR_KEY, // code
        selectedFile, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.isUploadingAvatar = selectedFile != null
          this.avatarSuccessfullyUploadedAlert = false

          return this.isUploadingAvatar
        },

        // onSuccess
        (data) => {
          this.isUploadingAvatar = false
          EnvX.log(`📸 avatar url =[${data.url}]`)
          this.formProfile.avatar = URL.createObjectURL(selectedFile)
          this.avatarSuccessfullyUploadedAlert = true
          event.target.value = null
        },

        // All sort of errors
        (error, _unusedDescription) => {
          this.isUploadingAvatar = false
          if (error.message === 'AvatarRecord.imageSize') {
            this.showSavedFailure = true
            this.savedFailureErrorMessage = 'The image you tried to upload is larger than 5 MB'
          }
          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          }
        }
      )
    },

    clearFile() {
      this.formProfile.avatar = null
    }
  }
}
</script>

<style scoped>
._next {
  position: relative;
  top: +24px;
  width: 100%;
}

.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 16px 10px;
  position: relative;
  width: 100%;
}

.text-fields-row {
  display: flex;
}

.nohover-button::before {
  display: none;
}

.profile-image-style {
  border-radius: 20px;
}

.video-max-width {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer {
  margin: 0;
}

.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.progress-round-overlay,
.progress-square-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.progress-round-overlay {
  border-radius: 50%;
}
.progress-square-overlay {
  border-radius: 15%;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
