const ProfessionsData = [
  {
    name: 'Attorney - Bankruptcy',
    id: 'profession-attorneybankruptcy'
  },
  {
    name: 'Attorney - Estate/Probate',
    id: 'profession-estateattorney'
  },
  {
    name: 'Attorney - Family Law',
    id: 'profession-attorneyfamilylaw'
  },
  {
    name: 'Attorney - Real Estate',
    id: 'profession-attorneyrealestate'
  },
  {
    name: 'Commercial Broker',
    id: 'profession-commercialbroker'
  },
  {
    name: 'Commercial Lender',
    id: 'profession-commerciallender'
  },
  {
    name: 'Electrical Professional',
    id: 'profession-electricalprofessional'
  },
  {
    name: 'Estate Planning Coordinator',
    id: 'profession-estateplanningcoordinator'
  },
  {
    name: 'Financial Advisor',
    id: 'profession-financialadvisor'
  },
  {
    name: 'Flooring Professional',
    id: 'profession-flooringprofessional'
  },
  {
    name: 'General Contractor',
    id: 'profession-generalcontractor'
  },
  {
    name: 'General Handyman',
    id: 'profession-generalhandyman'
  },
  {
    name: 'Head Hunting Professional',
    id: 'profession-headhuntingprofessional'
  },
  {
    name: 'HVAC Professional',
    id: 'profession-hvacprofessional'
  },
  {
    name: 'Insurance Professional',
    id: 'profession-insurancebroker'
  },
  {
    name: 'Landscaping Professional',
    id: 'profession-landscapingprofessional'
  },
  {
    name: 'Moving Professional',
    id: 'profession-movingprofessional'
  },
  {
    name: 'Painting Professional',
    id: 'profession-paintingprofessional'
  },
  {
    name: 'Pest Control Professional',
    id: 'profession-pestcontrolprofessional'
  },
  {
    name: 'Plumbing Professional',
    id: 'profession-plumbingprofessional'
  },
  {
    name: 'Pool Service Professional',
    id: 'profession-poolserviceprofessional'
  },
  {
    name: 'Property Inspector',
    id: 'profession-homeinspector'
  },
  {
    name: 'Property Manager',
    id: 'profession-propertymanager'
  },
  {
    name: 'Real Estate Agent',
    id: 'profession-realtor'
  },
  {
    name: 'Residential Lender',
    id: 'profession-loanofficer'
  },
  {
    name: 'Reverse Mortgage Professional',
    id: 'profession-reversemortgageprofessional'
  },
  {
    name: 'Roofing Professional',
    id: 'profession-roofingprofessional'
  },
  {
    name: 'Senior Advisor',
    id: 'profession-senioradvisor'
  },
  {
    name: 'Solar Panel Professional',
    id: 'profession-solarpanelprofessional'
  },
  {
    name: 'Tax Professional',
    id: 'profession-taxprofessional'
  },
  {
    name: 'Tree Trimming Professional',
    id: 'profession-treetrimmingprofessional'
  },
  {
    name: 'ProInsight Guide',
    id: 'profession-proinsightbuilder'
  },
  {
    name: 'ProInsight Trainer',
    id: 'profession-coach'
  }
]

export { ProfessionsData }
